/** @format */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import injectSheet from 'react-jss';
import styles from './data-room.styles';
import { ContentExplorer, ContentPreview } from 'box-ui-elements';
import messages from 'box-ui-elements/i18n/en-US';
import 'box-ui-elements/dist/explorer.css';
import 'box-ui-elements/dist/explorer.css';
import Loading from '../../../../components/common/loading/loading';

// https://app.box.com/developers/console/app/709914/configuration
class DataRoom extends Component {
  render() {
    const { company, currentFolderId, search } = this.props;
    const rootFolderId = company.get('company').dataRoomId;
    const options = {
      token: company.get('boxToken'),
      rootFolderId: rootFolderId,
      canPreview: true,
      canDownload: true,
      canDelete: true,
      canUpload: true,
      currentFolderId: currentFolderId ? currentFolderId : rootFolderId,
    };
    return company.get('boxToken') ? (
      search && search.file ? (
        <ContentPreview hasHeader={false} fileId={currentFolderId} token={company.get('boxToken')} />
      ) : (
        <ContentExplorer logoUrl="" language="en-US" messages={messages} {...options} />
      )
    ) : (
      <Loading />
    );
  }
}

const withStyles = injectSheet(styles);
const withState = connect((store) => store);

export default withStyles(withState(DataRoom));
