/** @format */

import request from './api';
import { Map } from 'immutable';

import { company } from './entities';
import { actionSucceeds } from '../lib/helpers';
import { folders, files } from '../lib/criticalFiles';

export const types = {
  createCompany: 'COMPANY_CREATE',
  updateCompany: 'COMPANY_UPDATE',
  listCompany: 'COMPANY_LIST',
  fetchCompany: 'COMPANY_FETCH',
  deleteCompany: 'COMPANY_DELETE',
  getBoxToken: 'COMPANY_GET_BOX_TOKEN',
  getBoxFolders: 'COMPANY_GET_BOX_FOLDER_IDS',
  getBoxFiles: 'COMPANY_GET_BOX_FILE_IDS',
};

export function deleteCompany(id) {
  return company.delete(types.deleteCompany, id);
}

export function fetchCompany(id, query) {
  return company.get(types.fetchCompany, id, query);
}

export function listCompany() {
  return company.list(types.listCompany, []);
}

export function editCompany(id, _company) {
  return company.update(types.updateCompany, id, _company, []);
}

export function createCompany(_company) {
  return company.create(types.createCompany, _company, []);
}

export function getBoxToken(companyId) {
  return request(types.getBoxToken, '/box/login', 'GET', null, [{ companyId }]);
}

export function getBoxFolders(companyId) {
  const folderNames = folders.toString();
  return request(types.getBoxFolders, '/box/folderIds', 'GET', null, [{ companyId }, { folderNames }]);
}

export function getBoxFiles(companyId) {
  const fileNames = files.toString();
  return request(types.getBoxFiles, '/box/fileIds', 'GET', null, [{ companyId }, { fileNames }]);
}

const INITIAL_STATE = Map({
  isLoading: true,
  company: {},
  companies: [],
  boxToken: null,
  boxFolders: [],
  boxFiles: [],
});

export default function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.listCompany:
      return state.merge(
        Map({
          isLoading: action.isLoading,
          companies: actionSucceeds(action) ? action.body.data : [],
        })
      );
    case types.fetchCompany:
      return state.merge(
        Map({
          isLoading: action.isLoading,
          company: actionSucceeds(action) ? action.body : {},
        })
      );
    case types.updateCompany:
      return state.merge(
        Map({
          isLoading: action.isLoading,
          companies: actionSucceeds(action)
            ? state.get('companies').map(company => {
                if (company._id === action.body._id) {
                  return action.body;
                } else {
                  return company;
                }
              })
            : state.get('companies'),
        })
      );
    case types.deleteCompany:
      return state.merge(
        Map({
          isLoading: action.isLoading,
          companies: actionSucceeds(action)
            ? state.get('companies').filter(company => {
                return company._id !== action.body._id;
              })
            : state.get('companies'),
        })
      );
    case types.createCompany:
      return state.merge(
        Map({
          isLoading: action.isLoading,
          companies: actionSucceeds(action) ? state.get('companies').concat([action.body]) : state.get('companies'),
        })
      );

    case types.getBoxToken:
      return state.merge(
        Map({
          ...state,
          boxToken: actionSucceeds(action) ? action.body.accessToken : state.boxToken,
        })
      );

    case types.getBoxFolders:
      return state.merge(
        Map({
          isLoading: action.isLoading,
          boxFolders: actionSucceeds(action) ? action.body : [],
        })
      );
    case types.getBoxFiles:
      return state.merge(
        Map({
          isLoading: action.isLoading,
          boxFiles: actionSucceeds(action) ? action.body : [],
        })
      );
    default:
      return state;
  }
}
