/** @format */

export default {
  nav: {
    background: 'rgba(153, 206, 211, .05)',
    height: '5vh',
    marginLeft: 15,
  },
  menu: {
    marginLeft: 24, // 14 is flush to left
    // backgroundColor: Nav, // Adding this screws up right side border
    height: 53,
    float: 'left',
  },
  body: {
    height: '60%',
    float: 'left',
  },
};
