/** @format */

import { createStore, applyMiddleware, compose } from 'redux';
import { connectRouter, routerMiddleware } from 'connected-react-router';
import { thunk } from 'redux-thunk';
import { createBrowserHistory } from 'history';
import rootReducer from './modules';
import { IS_PROD } from './lib/constants';

export const history = createBrowserHistory();

const initialState = {};
const enhancers = [];
const middleware = [thunk, routerMiddleware(history)];

if (!IS_PROD) {
  const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__;

  if (typeof devToolsExtension === 'function') {
    enhancers.push(devToolsExtension());
  }
}

const composedEnhancers = compose(applyMiddleware(...middleware), ...enhancers);

const store = createStore(connectRouter(history)(rootReducer), initialState, composedEnhancers);

export default store;
