/** @format */

import React, { Component } from 'react';
import injectSheet from 'react-jss';
import { connect } from 'react-redux';
import { Grid, Menu } from 'semantic-ui-react';

import styles from './admin.styles';
import Loading from '../../components/common/loading/loading';
import ManageUser from './views/manage-user/manage-user';
import ManageCompany from './views/manage-company/manage-company';
import ManageContacts from './views/manage-contacts/manage-contacts';

class Admin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeItem: 'Manage User',
    };
  }

  render() {
    const { classes, user } = this.props;
    const activeUser = user.get('user') || {};
    const isAdmin = activeUser.isAdmin;
    const { activeItem } = this.state;

    return isAdmin ? (
      <Grid>
        <Grid.Row className={classes.menu}>
          <Menu id={'admin'} borderless pointing secondary>
            <Menu.Item name="Manage User" active={activeItem === 'Manage User'} onClick={this.handleItemClick} />
            <Menu.Item name="Manage Company" active={activeItem === 'Manage Company'} onClick={this.handleItemClick} />
            <Menu.Item
              name="Manage Contacts"
              active={activeItem === 'Manage Contacts'}
              onClick={this.handleItemClick}
            />
          </Menu>
        </Grid.Row>
        <Grid.Row className={classes.body}>{this.renderActivePane()}</Grid.Row>
      </Grid>
    ) : (
      <Loading />
    );
  }

  handleItemClick = (e, { name }) => {
    this.setState({ activeItem: name });
  };

  renderActivePane() {
    const { activeItem } = this.state;
    switch (activeItem) {
      case 'Manage User':
        return <ManageUser />;
      case 'Manage Company':
        return <ManageCompany />;
      case 'Manage Contacts':
        return <ManageContacts />;
      default:
        return <Loading />;
    }
  }
}

const withStyles = injectSheet(styles);
const withState = connect(store => store);
export default withStyles(withState(Admin));
