/** @format */

export default {
  dash: {
    width: '100%',
  },
  logoColumn: {
    marginLeft: 40,
    marginTop: '3% !important',
    display: 'flex !important',
    flexDirection: 'column',
  },
};
