/** @format */

export const PrimaryColor = '#83CED7';
export const SecondaryColor = '#BEE4EC';
export const DarkBlue = '#092B35';
export const White = '#ffffff';
export const LtGray = '#f4f4f6';

export const Header = '#283142';
export const Nav = White;
export const Background = '#eff3f6';
export const PrimaryText = '#31384a';
export const SecondaryText = '#6b7f99';

export const CashBalance = '#1990ea';
export const MonthlyRevenue = '#08c198';
export const MonthlyExpenses = '#9907c1';

export const BoxShadow = '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24) !important';
