/** @format */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import injectSheet from 'react-jss';
import { Grid, Form, Dropdown } from 'semantic-ui-react';
import { toast } from 'react-toastify';

import styles from './manage-contacts.styles';
import { createContact, listContactByCompany, editContact } from '../../../../modules/contact';
import { listCompany } from '../../../../modules/company';

const TOAST_POSITION = 'top-center';

class ManageContact extends Component {
  state = {
    _id: '',
    name: '',
    title: '',
    email: '',
    phone: '',
    companyId: '',
  };

  componentDidMount() {
    this.refreshData();
  }

  render() {
    const { classes, contact, company } = this.props;
    const companies = company.get('companies').map((_comp) => {
      return { key: _comp._id, text: _comp.name, value: _comp._id };
    });
    const contacts = contact.get('contacts').map((_contact) => {
      return { key: _contact._id, text: _contact.name, value: _contact._id };
    });

    return (
      <Grid className={classes.dash}>
        <Grid.Row>
          <Grid.Column className={classes.logoColumn} width={7}>
            <Dropdown
              placeholder="Select company"
              value={this.state.companyId}
              options={companies}
              fluid
              search
              selection
              name="companyId"
              onChange={this.selectCompany}
              loading={company.get('isLoading')}
            />
          </Grid.Column>
        </Grid.Row>
        {this.state.companyId && [
          contacts.length > 0 ? (
            <Grid.Row key="contactSelect">
              <Grid.Column className={classes.logoColumn} width={7}>
                <Dropdown
                  placeholder="Edit existing contact..."
                  value={this.state._id}
                  options={contacts}
                  fluid
                  search
                  selection
                  name="contacts"
                  onChange={this.setFormToContact}
                  loading={contact.get('isLoading')}
                />
              </Grid.Column>
            </Grid.Row>
          ) : (
            <span />
          ),
          <Grid.Row key="form">
            <Grid.Column className={classes.logoColumn} width={7}>
              <Form>
                {this.renderInputFields()}
                <Form.Group>
                  <Form.Button content={'Submit'} onClick={() => this.handleSubmit()} primary />
                  <Form.Button content={'Cancel'} onClick={() => this.resetAllFields()} />
                </Form.Group>
              </Form>
            </Grid.Column>
          </Grid.Row>,
        ]}
      </Grid>
    );
  }

  renderInputFields() {
    const inputFields = [
      { label: 'Name', field: 'name' },
      { label: 'Title', field: 'title' },
      { label: 'Email', field: 'email' },
      { label: 'Phone', field: 'phone' },
    ];

    return inputFields.map((field) => (
      <Form.Input
        key={field.field}
        label={field.label}
        placeholder={field.label}
        name={field.field}
        value={this.state[field.field] || ''}
        onChange={this.handleChange}
      />
    ));
  }

  resetAllFields() {
    this.setState({ companyId: '' });
    this.resetFields();
  }

  resetFields() {
    this.setState({
      _id: '',
      name: '',
      title: '',
      email: '',
      phone: '',
    });
  }

  handleChange = (e, { name, value }) => this.setState({ [name]: value });
  selectCompany = (e, { value }) => {
    this.props.dispatch(listContactByCompany(value));
    this.setState({ companyId: value });
  };

  setFormToContact = (e, { value }) => {
    const { _id, name, title, email, phone, companyId } = this.props.contact
      .get('contacts')
      .filter((c) => c._id === value)[0];
    this.setState({ _id, name, title, email, phone, companyId });
  };

  handleSubmit = async () => {
    const { dispatch } = this.props;
    const { _id, name, title, email, phone, companyId } = this.state;
    const newContact = { name, title, email, phone, companyId };

    if (_id !== '') {
      dispatch(editContact(_id, newContact)).then((res) => {
        if (res.error) {
          this.errorToast();
        } else {
          this.notify(true);
          this.resetFields();
          this.refreshData();
        }
      });
    } else {
      await dispatch(createContact(newContact)).then((res) => {
        if (res.error) {
          this.errorToast();
        } else {
          this.notify(false);
          this.resetFields();
          this.refreshData();
        }
      });
    }
  };

  notify = (isEdit) => {
    if (isEdit) {
      toast.success('Edit Successful!', {
        position: TOAST_POSITION,
      });
    } else {
      toast.success('Create Successful!', {
        position: TOAST_POSITION,
      });
    }
  };

  errorToast = () => {
    toast.error('Something went wrong...', {
      position: TOAST_POSITION,
    });
  };

  refreshData() {
    const { dispatch } = this.props;
    dispatch(listCompany());
  }
}

const withState = connect((store) => {
  return store;
});

export default injectSheet(styles)(withState(ManageContact));
