/** @format */
import { SecondaryText, PrimaryText } from '../../../styles/variables';

export default {
  value: {
    color: PrimaryText,
    fontSize: '15px',
    fontFamily: 'Proxima Nova, Open Sans, sans-serif',
  },
  divider: {
    marginTop: '3px !important',
    marginBottom: '3px !important',
  },
  label: {
    color: SecondaryText,
  },
};
