/** @format */
import { Header, Nav, Background, BoxShadow, PrimaryText } from '../../styles/variables';

export default {
  baseApp: {
    // maxWidth: 1600,
    minWidth: 1380,
    margin: '0 auto !important',
    boxShadow: BoxShadow,
    fontFamily: 'Proxima Nova, Open Sans, sans-serif',
    flex: 1,
  },
  header: {
    color: Header,
    height: '60px',
    backgroundColor: Header,
    borderBottom: '1px solid rgba(95, 99, 102, .2) !important',
    display: 'flex !important',
    justifyContent: 'center !important',
    alignItems: 'center !important',
  },
  navigationHeader: {
    color: '#4B5E65 !important',
    marginBottom: '30px !important',
    marginLeft: '15px !important',
    marginTop: 1000,
  },
  sidebar: {
    borderRight: '1px solid rgba(95, 99, 102, .2) !important',
    minHeight: '100vh',
    backgroundColor: Nav,
    paddingLeft: '0px !important',
    paddingRight: '0px !important',
  },
  menu: {
    border: '0px solid black !important',
    borderLeft: '0px solid black !important',
    borderTop: '0px solid black !important',
    borderRadius: '0px !important',
    backgroundColor: Nav,
  },
  container: {
    border: '0px solid black !important',
    borderLeft: '0px solid black !important',
    borderTop: '0px solid black !important',
    borderRadius: '0px !important',
    padding: '0px !important',
  },
  logos: {
    width: '40%',
  },
  xeroLogo: {},
  expensifyLogo: {},
  airBaseLogo: {},
  cartaLogo: {},
  pageName: {
    color: '#44566C !important',
  },
  segment: {
    border: '0px solid black !important',
    boxShadow: 'none !important',
    borderLeft: '0px solid black !important',
    borderTop: '0px solid black !important',
    borderRadius: '0px !important',
    width: '100% !important',
  },
  nameColumn: {},
  nameDropdown: {
    marginRight: 30,
    color: '#ffffff',
  },
  body: {
    width: '100% !important',
    backgroundColor: Background,
    padding: '0px !important',
  },
  menuHeader: {
    marginTop: 0,
  },
  menuIcon: {
    float: 'left !important',
    marginRight: '8px !important',
  },
  contactUs: {
    marginTop: 30,
  },
  bottomMenu: {
    position: 'absolute',
    bottom: 85,
    left: 25,
    // color: '#5F6366 !important',
  },
  partnerHeader: {
    color: PrimaryText,
    marginBottom: '20px !important',
  },
};
