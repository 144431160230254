/** @format */

import React from 'react';
import injectSheet from 'react-jss';
import styles from './information.styles';
import { Divider } from 'semantic-ui-react';

const InformationComponent = ({ classes, label, value }) => (
  <div className={classes.information}>
    <div className={classes.label}>{label}</div>
    {value ? (
      value.split('\n').map((val, index) => (
        <div key={index} className={classes.value}>
          {val}
        </div>
      ))
    ) : (
      <div className={classes.value}>{'----'}</div>
    )}
    <Divider className={classes.divider} />
  </div>
);

export default injectSheet(styles)(InformationComponent);
