/** @format */

import React, { Component } from 'react';
import { Table } from 'semantic-ui-react';
import injectSheet from 'react-jss';
import styles from './report-data.styles';

class ReportData extends Component {
  render() {
    const { report, classes } = this.props;
    return (
      <Table celled className={classes.table}>
        {report.rows.map((row, index) => {
          if (row.rowType === 'Header') {
            return (
              <Table.Header key={index}>
                <Table.Row>
                  {row.cells.map((cell, index) => (
                    <Table.HeaderCell key={index} className={classes.label}>
                      {cell.value}
                    </Table.HeaderCell>
                  ))}
                </Table.Row>
              </Table.Header>
            );
          } else {
            return (
              <Table.Body key={index}>
                <Table.Row>
                  <Table.Cell colSpan={report.colspan} className={classes.cell}>
                    <strong>{row.title}</strong>
                  </Table.Cell>
                </Table.Row>
                {this.renderValueRows(row)}
              </Table.Body>
            );
          }
        })}
      </Table>
    );
  }

  renderValueRows(row) {
    const { report, classes } = this.props;
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
    });
    return row.rows.map((thisRow, index) => {
      return (
        <Table.Row key={index}>
          {thisRow.cells &&
            thisRow.cells.map((thisCell, cellIndex) => {
              if (thisCell.value) {
                return (
                  <Table.Cell
                    key={cellIndex}
                    className={thisRow.rowType === 'SummaryRow' ? classes.summaryCell : classes.secondaryCell}
                  >
                    {report.reportID !== 'BankStatement' && '\xa0\xa0\xa0\xa0'}
                    {thisCell.value.indexOf('.') == -1 ||
                    (thisCell.value.indexOf('.') != -1 && thisCell.value.indexOf('%') != -1)
                      ? thisCell.value
                      : formatter.format(thisCell.value)}
                  </Table.Cell>
                );
              } else {
                if (thisCell.length) {
                  thisCell.map((extraCell, ecIndex) => {
                    return (
                      <Table.Cell key={ecIndex} className={classes.cell}>
                        <strong>
                          {extraCell.value.indexOf('.') == -1 ||
                          (extraCell.value.indexOf('.') != -1 && extraCell.value.indexOf('%') != -1)
                            ? extraCell.value
                            : formatter.format(extraCell.value)}
                        </strong>
                      </Table.Cell>
                    );
                  });
                } else {
                  return (
                    <Table.Cell key={cellIndex} className={classes.cell}>
                      &nbsp;
                    </Table.Cell>
                  );
                }
              }
            })}
        </Table.Row>
      );
    });
  }
}

const withStyles = injectSheet(styles);

export default withStyles(ReportData);
