/** @format */

export default {
  companyName: {
    marginLeft: 40,
  },
  body: {
    marginLeft: 20,
  },
  dash: {
    width: '100%',
    margin: '0 auto !important',
    // marginLeft: '30px !important',
  },
  partnerHeader: {
    width: '95%',
  },
  logoColumn: {
    marginLeft: 40,
    marginTop: '3% !important',
    display: 'flex !important',
    flexDirection: 'column',
  },
  column: {
    display: 'flex !important',
    float: 'left',
    // marginRight: 10,
    paddingLeft: '5 !important',
    paddingRight: '5 !important',
    justifyContent: 'center',
  },
  cards: {
    justifyItems: 'center',
    height: '90%',
    // marginLeft: 20,
    width: '90% !important',
    float: 'left',
    marginTop: '8%',
  },
  numbers: {
    height: '10%',
    width: '100%',
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
    marginLeft: 30,
  },
  number: {
    marginLeft: 10,
    float: 'left',
  },
};
