/** @format */

import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import App from './containers/app/app';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from 'react-router-dom';
import store from './store';
import { Route } from 'react-router-dom';

import Login from './containers/login/login';
import Callback from './containers/callback/callback';
import SecuredRoute from './components/common/secured-route/secured-route';

import 'react-toastify/dist/ReactToastify.css';
import 'semantic-ui-css/semantic.min.css';
import './index.css';

const target = document.querySelector('#root');
const root = createRoot(target);
root.render(
  <Provider store={store}>
    <BrowserRouter>
      <div>
        <Route exact path="/" component={Login} />
        <Route exact path="/callback" component={Callback} />
        <SecuredRoute path="/*" component={App} />
      </div>
    </BrowserRouter>
  </Provider>
);

serviceWorker.unregister();
