/** @format */
import { LtGray, BoxShadow, White } from '../../../styles/variables';

export default {
  container: {
    flex: 1,
    height: '100px',
    boxShadow: BoxShadow,
  },
  numberDiv: {
    height: '100%',
    color: LtGray,
    flex: 1,
  },
  number: {
    fontSize: '1.8em !important',
    color: White,
  },
  icon: {
    fontSize: 50,
    color: LtGray,
    alignText: 'center',
    marginRight: 1,
  },
  numberIcon: {
    marginTop: '22px !important',
  },
  description: {
    marginBottom: 10,
  },
  name: {
    color: White,
    opacity: 0.6,
  },
  date: {
    color: White,
    opacity: 0.4,
    float: 'right',
  },
};
