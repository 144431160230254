/** @format */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Grid, Dropdown, Input } from 'semantic-ui-react';
import injectSheet from 'react-jss';
import styles from './report.styles';
import moment from 'moment';

import ReportData from '../report-data/report-data';
import { fetchReport } from '../../../modules/reports';

const REPORTS = {
  '1': 'BalanceSheet',
  '2': 'TrialBalance',
  '3': 'ProfitAndLoss',
  '4': 'BankStatement',
  '5': 'BudgetSummary',
  '6': 'ExecutiveSummary',
  '7': 'BankSummary',
  '8': 'AgedReceivablesByContact',
  '9': 'AgedPayablesByContact',
  '10': 'TenNinetyNine',
};

const TIMEFRAMES = [
  { text: 'Timeframe: Monthly', value: 'MONTH' },
  { text: 'Timeframe: Quarterly', value: 'QUARTER' },
  { text: 'Timeframe: Year', value: 'YEAR' },
].map(_timeframe => {
  return { key: _timeframe.text, text: _timeframe.text, value: _timeframe.value };
});

const PERIODS = [
  { text: 'Periods: 1', value: 1 },
  { text: 'Periods: 2', value: 2 },
  { text: 'Periods: 3', value: 3 },
  { text: 'Periods: 4', value: 4 },
  { text: 'Periods: 5', value: 5 },
  { text: 'Periods: 6', value: 6 },
  { text: 'Periods: 7', value: 7 },
  { text: 'Periods: 8', value: 8 },
  { text: 'Periods: 9', value: 9 },
  { text: 'Periods: 10', value: 10 },
  { text: 'Periods: 11', value: 11 },
].map(_period => {
  return { key: _period.text, text: _period.text, value: _period.value };
});

class ReportComponent extends Component {
  constructor(props) {
    super(props);
    this.state = { periods: 1, timeframe: 'YEAR' };
  }

  render() {
    const { reports, classes } = this.props;
    const report = reports.get('report');
    return (
      <div className={classes.container}>
        <h3 className={classes.cell}>{report && report.reportName ? report.reportName : 'Loading...'}</h3>
        {this.reportHeader()}
        {report && report.colspan ? <ReportData report={report} /> : <div />}
      </div>
    );
  }

  reportHeader() {
    const { activeReport } = this.props;

    switch (REPORTS[activeReport]) {
      case 'BankStatement':
        return this.baseReport(true, true, false);
      case 'ExecutiveSummary':
        return this.baseReport(false, false, true);
      case 'BalanceSheet':
        return this.reportWithTimeframe(false, false, true);
      case 'ProfitAndLoss':
        return this.reportWithTimeframe(true, true, false);
      case 'AgedPayablesByContact':
        return this.reportWithContacts(false, false, true);
      default:
        return this.baseReport();
    }
  }

  reportWithTimeframe(toDate, fromDate, date) {
    const { classes } = this.props;
    return (
      <Grid columns={3}>
        {/*this.reportDate()*/}
        <Grid.Column>
          <Dropdown
            className={classes.dropdown}
            placeholder="Timeframe"
            value={this.state.timeframe}
            options={TIMEFRAMES}
            fluid
            selection
            name="timeframe"
            onChange={this.fetchReport}
          />
        </Grid.Column>
        <Grid.Column>
          <Dropdown
            className={classes.dropdown}
            placeholder="Periods"
            value={this.state.periods}
            options={PERIODS}
            fluid
            selection
            name="periods"
            onChange={this.fetchReport}
          />
        </Grid.Column>
        {this.dates(toDate, fromDate, date)}
      </Grid>
    );
  }

  reportWithContacts(toDate, fromDate, date) {
    const { reports, classes } = this.props;
    const contacts = reports.get('contacts');
    const contactsValues = contacts.map(_contact => {
      return { key: _contact.id, text: _contact.name, value: _contact.id };
    });

    return (
      <Grid columns={2}>
        {/*this.reportDate()*/}
        <Grid.Column>
          <Dropdown
            className={classes.dropdown}
            placeholder="Contacts"
            value={this.state.contact}
            options={contactsValues}
            fluid
            selection
            name="contactID"
            onChange={this.fetchReport}
          />
        </Grid.Column>
        {this.dates(toDate, fromDate, date)}
      </Grid>
    );
  }

  baseReport(toDate, fromDate, date) {
    return (
      <Grid columns={1}>
        {this.dates(toDate, fromDate, date)}
        {/*this.reportDate()*/}
      </Grid>
    );
  }

  dates(toDate, fromDate, date) {
    const { classes } = this.props;
    return (
      <Grid columns={2}>
        {fromDate ? (
          <Grid.Column>
            <Input
              className={classes.dropdown}
              placeholder={!this.state.fromDate || this.state.fromDate === '' ? 'From Date' : ''}
              value={this.state.fromDate}
              type={'date'}
              fluid
              name="fromDate"
              onChange={this.fetchReport}
            />
          </Grid.Column>
        ) : (
          undefined
        )}
        {toDate ? (
          <Grid.Column>
            <Input
              className={classes.dropdown}
              placeholder={!this.state.toDate || this.state.toDate === '' ? 'To Date' : ''}
              value={this.state.toDate}
              type={'date'}
              fluid
              name="toDate"
              onChange={this.fetchReport}
            />
          </Grid.Column>
        ) : (
          undefined
        )}
        {date ? (
          <Grid.Column>
            <Input
              className={classes.dropdown}
              placeholder="Date"
              value={this.state.date}
              type={'date'}
              fluid
              name="date"
              onChange={this.fetchReport}
            />
          </Grid.Column>
        ) : (
          undefined
        )}
      </Grid>
    );
  }
  reportDate() {
    const { reports, classes } = this.props;
    const report = reports.get('report');
    return report ? (
      <Grid.Column className={classes.reportDate}>
        <span className={classes.label}>{'Report Date: '}</span>
        {moment(report.ReportDate).format('DD MMM YYYY')}
      </Grid.Column>
    ) : (
      <Grid.Column />
    );
  }

  fetchReport = (e, { name, value }) => {
    const { dispatch, activeReport, company } = this.props;
    const prevValues = this.state;
    const params = Object.entries(Object.assign(prevValues, { [name]: value })).map(obj => ({ [obj[0]]: obj[1] }));
    console.log(params);
    dispatch(fetchReport(activeReport, company.get('company')._id, params));
    this.setState({ [name]: value });
  };
}

const withStyles = injectSheet(styles);
const withState = connect(store => store);

export default withStyles(withState(ReportComponent));
