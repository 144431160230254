import { PrimaryText, SecondaryText, BoxShadow } from '../../../styles/variables';

/** @format */

export default {
  container: {
    width: '95%',
  },
  reportDate: {
    textAlign: 'right',
  },
  label: {
    opacity: 0.5,
  },
  table: {
    boxShadow: BoxShadow,
    marginBottom: '50px !important',
    borderRadius: '1px !important',
  },
  cell: {
    color: `${PrimaryText} !important`,
  },
  secondaryCell: {
    color: `${SecondaryText} !important`,
  },
  summaryCell: {
    color: `${SecondaryText} !important`,
  },
  dropdown: {
    boxShadow: BoxShadow,
  },
};
