/** @format */

import auth0 from 'auth0-js';
import { AUTH_CONFIG } from '../lib/constants';

class Auth {
  constructor() {
    this.auth0 = new auth0.WebAuth(AUTH_CONFIG);

    this.getProfile = this.getProfile.bind(this);
    this.handleAuthentication = this.handleAuthentication.bind(this);
    this.isAuthenticated = this.isAuthenticated.bind(this);
    this.signIn = this.signIn.bind(this);
    this.signOut = this.signOut.bind(this);
    if (localStorage.getItem('expiresAt')) {
      const expiresAt = parseInt(localStorage.getItem('expiresAt'));
      if (expiresAt < new Date().getTime()) {
        this.idToken = localStorage.getItem('bearer');
        this.expiresAt = expiresAt;
        this.profile = localStorage.getItem('profile');
      }
    }
  }

  _checkExpiresAtFromStorage() {
    if (localStorage.getItem('expiresAt')) {
      const expiresAt = parseInt(localStorage.getItem('expiresAt'));
      this.expiresAt = expiresAt;
      return expiresAt > new Date().getTime();
    }
    return false;
  }

  getProfile() {
    return this.profile;
  }

  getIdToken() {
    return this.idToken;
  }

  handleAuthentication() {
    return new Promise((resolve, reject) => {
      this.auth0.parseHash((err, authResult) => {
        if (err) return reject(err);
        if (!authResult || !authResult.idToken) {
          return reject(err);
        }
        this.idToken = authResult.idToken;
        this.profile = authResult.idTokenPayload;
        // set the time that the id token will expire at
        this.expiresAt = authResult.expiresIn * 1000 + new Date().getTime();
        resolve({
          profile: this.profile,
          idToken: this.idToken,
          expiresAt: this.expiresAt,
        });
      });
    });
  }

  isAuthenticated() {
    if (this.expiresAt) {
      return new Date().getTime() < this.expiresAt;
    }
    return this._checkExpiresAtFromStorage();
  }

  signIn() {
    this.auth0.authorize();
  }

  signOut() {
    // clear id token, profile, and expiration
    this.auth0.logout({
      returnTo: AUTH_CONFIG.returnTo,
    });
    this.idToken = null;
    this.profile = null;
    this.expiresAt = null;
    localStorage.clear();
  }
}

const auth0Client = new Auth();

export default auth0Client;
