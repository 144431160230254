/** @format */

import { Map } from 'immutable';
import auth from '../lib/auth';
import { user } from './entities';
import { actionSucceeds } from '../lib/helpers';

export const types = {
  invalidate: 'INVALIDATE',
  loadUser: 'USER_LOAD',
  setUser: 'USER_SET',
  setAuth: 'USER_SET_AUTH',
  listUser: 'USER_LIST',
  editUser: 'USER_UPDATE',
  createUser: 'USER_CREATE',
};

export function invalidateLogin() {
  return { type: types.invalidate };
}

export function loadUser(email) {
  return user.list(types.loadUser, [{ email }, { $populate: 'companies' }]);
}

export function setAuth(auth) {
  return {
    type: types.setAuth,
    auth,
  };
}

export function listUser() {
  return user.list(types.listUser, []);
}

export function editUser(id, _user) {
  return user.update(types.editUser, id, _user);
}

export function createUser(_user) {
  return user.create(types.createUser, _user, []);
}

const INITIAL_STATE = Map({
  user: null,
  users: [],
  isLoading: true,
});

export default function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.loadUser:
      return state.merge(
        Map({
          isLoading: action.isLoading,
          user: actionSucceeds(action)
            ? {
                ...state.user,
                ...action.body.data[0],
              }
            : state.user,
        })
      );

    case types.setAuth:
      return state.merge(
        Map({
          ...state,
          user: {
            ...state.user,
            auth: action.auth,
          },
        })
      );

    case types.invalidate:
      auth.signOut();
      return Map({
        user: null,
        users: [],
        isLoading: false,
      });

    case types.listUser:
      return state.merge(
        Map({
          isLoading: action.isLoading,
          users: actionSucceeds(action) ? action.body.data : [],
        })
      );

    default:
      return state;
  }
}
