export const folderTypes = {
  'SS4 - Filings and Misc. Corporate Documents': 'IRS doc that contains EIN and tax information',
  'COI - Certificates of Incorporation': 'Certificates of insurance required for some contracts',
  'Lease Agreements - Real Property Leases': 'All lease agreements held by the company',
  'Contractor Agreements - Consulting Agreements': 'All general and specific contractor agreements',
  'Insurance Agreements - Insurance Policies and Coverage Summary': 'All insurance agreements for the business',
};

export const folders = ['CRITICAL'];

export const files = ['CRITICAL'];
