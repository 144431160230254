/** @format */

export default {
  companyName: {
    marginLeft: 20,
  },
  body: {
    marginLeft: 20,
  },
};
