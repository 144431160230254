/** @format */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import injectSheet from 'react-jss';
import { Grid, Form, Dropdown } from 'semantic-ui-react';
import { toast } from 'react-toastify';

import styles from './manage-user.styles';
import { createUser, editUser, listUser } from '../../../../modules/user';
import { listCompany } from '../../../../modules/company';

const TOAST_POSITION = 'top-center';

class ManageUser extends Component {
  state = {
    _id: '',
    firstName: '',
    lastName: '',
    email: '',
    isAdmin: false,
    companies: [],
  };

  async componentDidMount() {
    this.refreshData();
  }

  render() {
    const { classes, user } = this.props;
    const users = user.get('users').map((_user) => {
      return { key: _user._id, text: `${_user.firstName} ${_user.lastName}`, value: _user._id };
    });
    return (
      <Grid className={classes.dash}>
        <Grid.Row>
          <Grid.Column className={classes.logoColumn} width={7}>
            <Dropdown
              placeholder="Edit existing user..."
              value={this.state._id}
              options={users}
              fluid
              search
              selection
              name="users"
              onChange={this.setFormToUser}
              loading={user.get('isLoading')}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column className={classes.logoColumn} width={7}>
            <Form>
              {this.renderInputFields()}
              <Form.Field>
                <Form.Checkbox
                  label="User has admin privileges"
                  checked={this.state.isAdmin}
                  onChange={this.toggleCheckbox}
                  name="isAdmin"
                />
              </Form.Field>
              {this.renderCompanySelect()}
              <Form.Group>
                <Form.Button content={'Submit'} onClick={() => this.handleSubmit()} primary />
                <Form.Button content={'Cancel'} onClick={() => this.resetFields()} />
              </Form.Group>
            </Form>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }

  renderInputFields() {
    const inputFields = [
      { label: 'First Name', field: 'firstName' },
      { label: 'Last Name', field: 'lastName' },
      { label: 'Email', field: 'email' },
    ];

    return inputFields.map((field) => (
      <Form.Input
        key={field.field}
        label={field.label}
        placeholder={field.label}
        name={field.field}
        value={this.state[field.field] || ''}
        onChange={this.handleChange}
      />
    ));
  }

  renderCompanySelect() {
    const { company } = this.props;
    const { companies } = this.state;
    const allCompanies = company.get('companies').map((_comp) => {
      return { key: _comp._id, text: _comp.name, value: _comp._id };
    });
    return (
      allCompanies && (
        <Form.Dropdown
          placeholder="Companies"
          value={companies}
          options={allCompanies}
          fluid
          multiple
          search
          selection
          name="companies"
          onChange={this.handleChange}
          loading={company.get('isLoading')}
        />
      )
    );
  }

  resetFields() {
    this.setState({
      _id: '',
      firstName: '',
      lastName: '',
      email: '',
      isAdmin: false,
      companies: [],
    });
  }

  handleChange = (e, { name, value }) => this.setState({ [name]: value });
  toggleCheckbox = (e, object) => this.setState({ [object.name]: !this.state[object.name] });

  setFormToUser = (e, { value }) => {
    const { _id, firstName, lastName, email, isAdmin, companies } = this.props.user
      .get('users')
      .filter((u) => u._id === value)[0];
    this.setState({ _id, firstName, lastName, email, isAdmin, companies });
  };

  handleSubmit = async () => {
    const { dispatch } = this.props;
    const { _id, firstName, lastName, email, companies, isAdmin } = this.state;
    const newUser = { firstName, lastName, email, companies, isAdmin, activeCompany: companies[0] };

    if (_id !== '') {
      dispatch(editUser(_id, newUser)).then((res) => {
        if (res.error) {
          this.errorToast();
        } else {
          this.notify(true);
          this.resetFields();
          this.refreshData();
        }
      });
    } else {
      await dispatch(createUser(newUser)).then((res) => {
        if (res.error) {
          this.errorToast();
        } else {
          this.notify(false);
          this.resetFields();
          this.refreshData();
        }
      });
    }
  };

  notify = (isEdit) => {
    if (isEdit) {
      toast.success('Edit Successful!', {
        position: TOAST_POSITION,
      });
    } else {
      toast.success('Create Successful!', {
        position: TOAST_POSITION,
      });
    }
  };

  errorToast = () => {
    toast.error('Something went wrong...', {
      position: TOAST_POSITION,
    });
  };

  refreshData() {
    const { dispatch } = this.props;
    dispatch(listUser());
    dispatch(listCompany());
  }
}

const withState = connect((store) => {
  return store;
});

export default injectSheet(styles)(withState(ManageUser));
