/** @format */

import { task } from './entities';
import { actionSucceeds } from '../lib/helpers';
import { Map } from 'immutable';

export const types = {
  createTask: 'TASK_CREATE',
  updateTask: 'TASK_UPDATE',
  listTask: 'TASK_LIST',
  fetchTask: 'TASK_FETCH',
  deleteTask: 'TASK_DELETE',
};

export function deleteTask(id) {
  return task.delete(types.deleteTask, id);
}

export function fetchTask(id) {
  return task.get(types.fetchTask, id, []);
}

export function listTask(query) {
  return task.list(types.listTask, query);
}

export function listTaskByCompany(companyId) {
  return task.list(types.listTask, [{ companyId }]);
}

export function editTask(id, _task) {
  return task.update(types.updateTask, id, _task, []);
}

export function createTask(_task) {
  return task.create(types.createTask, _task, []);
}

const INITIAL_STATE = Map({
  task: {},
  tasks: [],
});

export default function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.listTask:
      return state.merge(
        Map({
          isLoading: action.isLoading,
          tasks: actionSucceeds(action) ? action.body.data : [],
        })
      );
    case types.updateTask:
      return state.merge(
        Map({
          isLoading: action.isLoading,
          tasks: actionSucceeds(action)
            ? state.get('tasks').map((task) => {
                if (task._id === action.body._id) {
                  return action.body;
                } else {
                  return task;
                }
              })
            : state.get('tasks'),
        })
      );
    case types.deleteTask:
      return state.merge(
        Map({
          isLoading: action.isLoading,
          tasks: actionSucceeds(action)
            ? state.get('tasks').filter((task) => {
                return task._id !== action.body._id;
              })
            : state.get('tasks'),
        })
      );
    case types.createTask:
      return state.merge(
        Map({
          isLoading: action.isLoading,
          tasks: actionSucceeds(action) ? state.get('tasks').concat([action.body]) : state.get('tasks'),
        })
      );
    default:
      return state;
  }
}
