/** @format */

import { combineReducers } from 'redux';
import user from './user';
import contact from './contact';
import company from './company';
import reports from './reports';
import task from './task';

export default combineReducers({
  user,
  contact,
  company,
  reports,
  task,
});
