import React from 'react';
import { FileUploader } from 'react-drag-drop-files';

const fileTypes = ['PDF'];

function FileUpload({ setFile }) {
  const handleChange = (file) => {
    setFile(file);
  };
  return (
    <FileUploader
      handleChange={handleChange}
      name="file"
      types={fileTypes}
      label={'Drag an invoice or click here to submit it to AR'}
      maxSize="10"
      classes="drop_area"
    />
  );
}

export default FileUpload;
