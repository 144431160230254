/** @format */
import React from 'react';
import { Icon } from 'semantic-ui-react';
import injectSheet from 'react-jss';
import styles from './number.styles';
import { Grid } from 'semantic-ui-react';

import { parseDollars } from '../../../support/parsers';

const NumberComponent = ({ classes, name, date, icon, number, color }) => (
  <Grid className={classes.container}>
    <Grid.Column width={4} className={classes.icon} style={{ backgroundColor: color }}>
      <Icon name={icon} className={classes.numberIcon} />
    </Grid.Column>
    <Grid.Column width={12} className={classes.numberDiv} style={{ backgroundColor: color, opacity: 0.9 }}>
      <p className={classes.number}>{parseDollars(number)}</p>
      <p className={classes.description}>
        <span className={classes.name}>{name}</span>
        <span className={classes.date}>{date}</span>
      </p>
    </Grid.Column>
  </Grid>
);

export default injectSheet(styles)(NumberComponent);
