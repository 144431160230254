// server

const productionBaseUrl = 'https://reverent-wing-fb3746.netlify.app';
const localBaseUrl = 'http://localhost:3000';
export const IS_PROD = process.env.NODE_ENV === 'production';
const url = IS_PROD ? productionBaseUrl : localBaseUrl;

const AUTH0_DOMAIN = 'projectbased.auth0.com';
const AUTH0_CLIENT_ID = '55S2IKQo4PjXz3iR41dicIP1YAO9Dttj';
const AUTH0_CALLBACK_URL = `${url}/callback`;

export const AUTH_CONFIG = {
  domain: AUTH0_DOMAIN,
  clientID: AUTH0_CLIENT_ID,
  redirectUri: AUTH0_CALLBACK_URL,
  returnTo: productionBaseUrl,
  responseType: 'token id_token',
  scope: 'openid profile email',
};
