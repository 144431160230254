/** @format */
import { Background, Header, BoxShadow } from '../../../styles/variables';

export default {
  title: {
    color: Header,
    fontSize: '15px !important',
    fontWeight: '300 !important',
  },
  card: {
    backgroundColor: Background,
    boxShadow: BoxShadow,
  },
  content: {
    flexGrow: '0 !important',
  },
};
