/** @format */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import injectSheet from 'react-jss';
import { Grid, Form, Dropdown, Label, Segment } from 'semantic-ui-react';
import { toast } from 'react-toastify';

import styles from './manage-company.styles';
import { listCompany, createCompany, editCompany, deleteCompany } from '../../../../modules/company';

const TOAST_POSITION = 'top-center';
const VALUE_HIDDEN = '********';

const XERO_CLIENT_ID = 'F8225A535BBA4F2D8659F15F05AC94AB';
const XERO_REDIRECT_URI = 'https://startop-dev.herokuapp.com/xero/redirect';

class ManageCompany extends Component {
  state = {
    _id: '',
    name: '',
    address: '',
    dateOfIncorporation: '',
    ein: '',
    stateCorporationNumber: '',
    naics: '',
    dataRoomId: '',
    xeroPrivateKey: '',
    xeroConsumerPublicKey: '',
    xeroConsumerPrivateKey: '',
    asOfDate: '',
    fullyDilutedSharesOutstanding: '',
    currentPreferredPrice: '',
    currentCommonPrice: '',
    currentValuation: '',
    dateOf409aValuation: '',
    checking: '',
    routing: '',
  };

  componentDidMount() {
    this.refreshData();
  }

  render() {
    const { classes, company } = this.props;
    const companies = company.get('companies').map((_comp) => {
      return { key: _comp._id, text: _comp.name, value: _comp._id };
    });

    return (
      <Grid className={classes.dash}>
        <Grid.Row>
          <Grid.Column className={classes.logoColumn} width={7}>
            <Dropdown
              placeholder="Edit existing company..."
              value={this.state._id}
              options={companies}
              // clearable
              fluid
              search
              selection
              name="companies"
              onChange={this.setFormToCompany}
              loading={company.get('isLoading')}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column className={classes.logoColumn}>
            <Form>
              <Segment width={14}>
                <Label attached="top">Company Info</Label>
                {this.renderInfoFields()}
              </Segment>
              <Segment width={14}>
                <Label attached="top">Integration Info</Label>
                {this.renderIntegrationFields()}
                <Form.Button content={'Authenticate Xero'} onClick={() => this.handleXero(this.state._id)} primary />
              </Segment>
              <Segment width={14}>
                <Label attached="top">Cap Table Info</Label>
                {this.renderCapTableFields()}
              </Segment>
              <Form.Group>
                <Form.Button content={'Submit'} onClick={() => this.handleSubmit()} primary />
                <Form.Button content={'Cancel'} onClick={() => this.resetFields()} />
                {this.state._id !== '' ? (
                  <Form.Button content={'Delete'} onClick={() => this.deleteCompany()} secondary />
                ) : undefined}
              </Form.Group>
            </Form>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }

  renderInfoFields() {
    const inputFields = [
      [
        { label: 'Name', field: 'name' },
        { label: 'Address', field: 'address' },
      ],
      // { label: 'City', field: 'city' },
      // { label: 'State of Incorporation', field: 'stateOfIncorporation' },
      // { label: 'Zip Code', field: 'zipcode' },
      [
        { label: 'Formation Date', field: 'dateOfIncorporation' },
        { label: 'EIN', field: 'ein' },
      ],
      [
        { label: 'NAICS #', field: 'naics' },
        {
          label: 'State Corp ID',
          field: 'stateCorporationNumber',
          component: (
            <Form.TextArea
              rows={3}
              key={'stateCorporationNumber'}
              label={'State Corp ID'}
              placeholder={'CA 12345\nDE 67890\n...'}
              name={'stateCorporationNumber'}
              value={this.state['stateCorporationNumber'] || ''}
              onChange={this.handleChange}
            />
          ),
        },
      ],
      [
        { label: 'Checking Account', field: 'checking' },
        { label: 'Routing Number', field: 'routing' },
      ],
    ];
    return this.renderInputFieldPairs(inputFields);
  }

  renderIntegrationFields() {
    const integrations = [
      [
        { label: 'Box Data Room ID', field: 'dataRoomId' },
        { label: 'AR Email', field: 'arEmail' },
      ],
    ];
    return this.renderInputFieldPairs(integrations);
  }

  renderCapTableFields() {
    const capTable = [
      [
        { label: 'As Of Date', field: 'asOfDate' },
        { label: 'Fully Diluted Outstanding', field: 'fullyDilutedSharesOutstanding' },
      ],
      [
        { label: 'Current Preferred Price', field: 'currentPreferredPrice' },
        { label: 'Current Common Price', field: 'currentCommonPrice' },
      ],
      [
        { label: 'Current Valuation', field: 'currentValuation' },
        { label: 'Date of 409a Valuation', field: 'dateOf409aValuation' },
      ],
    ];
    return this.renderInputFieldPairs(capTable);
  }

  renderInputFieldPairs(pairedFields) {
    return pairedFields.map((pair, index) => (
      <Form.Group key={index} widths={'equal'}>
        {pair.map((field) =>
          field.component ? (
            field.component
          ) : (
            <Form.Input
              // width={7}
              key={field.field}
              label={field.label}
              placeholder={field.label}
              name={field.field}
              value={this.state[field.field] || ''}
              onChange={this.handleChange}
            />
          )
        )}
      </Form.Group>
    ));
  }

  setFormToCompany = (e, { value }) => {
    const {
      _id,
      name,
      address,
      dateOfIncorporation,
      ein,
      stateCorporationNumber,
      naics,
      dataRoomId,
      arEmail,
      xeroConsumerPublicKey,
      asOfDate,
      fullyDilutedSharesOutstanding,
      currentPreferredPrice,
      currentCommonPrice,
      currentValuation,
      dateOf409aValuation,
      checking,
      routing,
    } = this.props.company.get('companies').filter((c) => c._id === value)[0];

    this.setState({
      _id,
      name,
      address,
      dateOfIncorporation,
      ein,
      stateCorporationNumber,
      naics,
      dataRoomId,
      arEmail,
      xeroConsumerPublicKey,
      xeroConsumerPrivateKey: VALUE_HIDDEN,
      xeroPrivateKey: VALUE_HIDDEN,
      asOfDate,
      fullyDilutedSharesOutstanding,
      currentPreferredPrice,
      currentCommonPrice,
      currentValuation,
      dateOf409aValuation,
      checking,
      routing,
    });
  };

  resetFields() {
    this.setState({
      _id: '',
      name: '',
      address: '',
      dateOfIncorporation: '',
      ein: '',
      arEmail: '',
      stateCorporationNumber: '',
      naics: '',
      dataRoomId: '',
      xeroPrivateKey: '',
      xeroConsumerPublicKey: '',
      xeroConsumerPrivateKey: '',
      asOfDate: '',
      fullyDilutedSharesOutstanding: '',
      currentPreferredPrice: '',
      currentCommonPrice: '',
      currentValuation: '',
      dateOf409aValuation: '',
      checking: '',
      routing: '',
    });
  }

  handleChange = (e, { name, value }) => this.setState({ [name]: value });

  handleXero = (companyId) => {
    window.open(
      `https://login.xero.com/identity/connect/authorize?response_type=code&client_id=${XERO_CLIENT_ID}&redirect_uri=${XERO_REDIRECT_URI}&scope=openid profile email offline_access accounting.contacts accounting.reports.read accounting.journals.read accounting.transactions.read&state=${companyId}`,
      '_blank'
    );
  };

  handleSubmit = async () => {
    const { dispatch } = this.props;
    const {
      _id,
      name,
      address,
      dateOfIncorporation,
      ein,
      stateCorporationNumber,
      naics,
      dataRoomId,
      arEmail,
      xeroPrivateKey,
      xeroConsumerPublicKey,
      xeroConsumerPrivateKey,
      asOfDate,
      fullyDilutedSharesOutstanding,
      currentPreferredPrice,
      currentCommonPrice,
      currentValuation,
      dateOf409aValuation,
      checking,
      routing,
    } = this.state;

    const company = {
      name,
      address,
      dateOfIncorporation,
      ein,
      stateCorporationNumber,
      naics,
      dataRoomId,
      arEmail,
      xeroConsumerPublicKey,
      asOfDate,
      fullyDilutedSharesOutstanding,
      currentPreferredPrice,
      currentCommonPrice,
      currentValuation,
      dateOf409aValuation,
      checking,
      routing,
    };

    if (xeroConsumerPrivateKey !== VALUE_HIDDEN) {
      company.xeroConsumerPrivateKey = xeroConsumerPrivateKey;
    }

    if (xeroPrivateKey !== VALUE_HIDDEN) {
      company.xeroPrivateKey = xeroPrivateKey;
    }

    if (_id !== '') {
      dispatch(editCompany(_id, company)).then((res) => {
        if (res.error) {
          this.errorToast();
        } else {
          this.notify(true);
          this.resetFields();
          this.refreshData();
        }
      });
    } else {
      await dispatch(createCompany(company)).then((res) => {
        if (res.error) {
          this.errorToast();
        } else {
          this.notify(false);
          this.resetFields();
          this.refreshData();
        }
      });
    }
  };

  notify = (isEdit) => {
    if (isEdit) {
      toast.success('Edit Successful!', {
        position: TOAST_POSITION,
      });
    } else {
      toast.success('Create Successful!', {
        position: TOAST_POSITION,
      });
    }
  };

  errorToast = () => {
    toast.error('Something went wrong...', {
      position: TOAST_POSITION,
    });
  };

  deleteCompany() {
    this.props.dispatch(deleteCompany(this.state._id)).then((res) => {
      if (res.error) {
        this.errorToast();
      } else {
        this.notify(true);
        this.resetFields();
      }
    });
  }
  refreshData() {
    const { dispatch } = this.props;
    dispatch(listCompany());
  }
}

const withStyles = injectSheet(styles);
const withState = connect((store) => store);
export default withStyles(withState(ManageCompany));
