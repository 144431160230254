/** @format */

import { contact } from './entities';
import { actionSucceeds } from '../lib/helpers';
import { Map } from 'immutable';

export const types = {
  createContact: 'CONTACT_CREATE',
  updateContact: 'CONTACT_UPDATE',
  listContact: 'CONTACT_LIST',
  fetchContact: 'CONTACT_FETCH',
  deleteContact: 'CONTACT_DELETE',
};

export function deleteContact(id) {
  return contact.delete(types.deleteContact, id);
}

export function fetchContact(id) {
  return contact.get(types.fetchContact, id, []);
}

export function listContact(query) {
  return contact.list(types.listContact, query);
}

export function listContactByCompany(companyId) {
  return contact.list(types.listContact, [{ companyId }]);
}

export function editContact(id, _contact) {
  return contact.update(types.updateContact, id, _contact, []);
}

export function createContact(_contact) {
  return contact.create(types.createContact, _contact, []);
}

const INITIAL_STATE = Map({
  contact: {},
  contacts: [],
});

export default function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.listContact:
      return state.merge(
        Map({
          isLoading: action.isLoading,
          contacts: actionSucceeds(action) ? action.body.data : [],
        })
      );
    case types.updateContact:
      return state.merge(
        Map({
          isLoading: action.isLoading,
          contacts: actionSucceeds(action)
            ? state.get('contacts').map(contact => {
                if (contact._id === action.body._id) {
                  return action.body;
                } else {
                  return contact;
                }
              })
            : state.get('contacts'),
        })
      );
    case types.deleteContact:
      return state.merge(
        Map({
          isLoading: action.isLoading,
          contacts: actionSucceeds(action)
            ? state.get('contacts').filter(contact => {
                return contact._id !== action.body._id;
              })
            : state.get('contacts'),
        })
      );
    case types.createContact:
      return state.merge(
        Map({
          isLoading: action.isLoading,
          contacts: actionSucceeds(action) ? state.get('contacts').concat([action.body]) : state.get('contacts'),
        })
      );
    default:
      return state;
  }
}
