/** @format */

export default {
  contact: {
    marginTop: '5px',
  },
  description: {
    marginLeft: '7px',
    opacity: '.9',
  },
  title: {
    fontSize: '18px',
  },
  name: {
    fontSize: '22px',
  },
};
