/** @format */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Grid } from 'semantic-ui-react';
import injectSheet from 'react-jss';

import styles from './messages.styles';

class Messages extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <Grid>
        <Grid.Row>
          <Grid.Column>{'Disabled'}</Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }
}

const withStyles = injectSheet(styles);
const withState = connect((store) => store);

export default withStyles(withState(Messages));
