/** @format */

export default {
  companyName: {
    marginLeft: 20,
  },
  menu: {
    marginLeft: 24, // 14 is flush to left
    // backgroundColor: Nav, // Adding this screws up right side border
    height: 53,
    float: 'left',
  },
  report: {
    marginLeft: 50,
    height: '60%',
    float: 'left',
  },
};
