/** @format */

import React from 'react';
import injectSheet from 'react-jss';
import { Card } from 'semantic-ui-react';

import styles from './card.styles';

const CardComponent = ({ classes, title, children }) => (
  <Card className={classes.card}>
    <Card.Content className={classes.content}>
      <Card.Header className={classes.title}>{title}</Card.Header>
    </Card.Content>
    <Card.Content>{children}</Card.Content>
  </Card>
);

export default injectSheet(styles)(CardComponent);
