/** @format */

import React, { Component } from 'react';
import { connect } from 'react-redux';

import auth0Client from '../../lib/auth';
import { loadUser, createUser } from '../../modules/user';
import Loading from '../../components/common/loading/loading';

class Callback extends Component {
  async componentDidMount() {
    const tokens = await auth0Client.handleAuthentication();
    localStorage.setItem('bearer', tokens.idToken);
    localStorage.setItem('expiresAt', tokens.expiresAt);
    localStorage.setItem('profile', JSON.stringify(tokens.profile));
    localStorage.setItem('email', tokens.profile.email); // TODO: Find a better way to do this
    const user = await this.props.dispatch(loadUser(tokens.profile.email));
    if (user.body.count === 0) {
      await this.props.dispatch(
        createUser({
          email: tokens.profile.name,
          externalId: tokens.profile.sub,
        })
      );
    }
    this.props.history.replace('/dashboard');
  }

  render() {
    return <Loading />;
  }
}

export default connect(
  null,
  null
)(Callback);
