/** @format */

import React from 'react';
import classnames from 'classnames';
import injectSheet from 'react-jss';
import { Image } from 'semantic-ui-react';
import styles from './image-link.styles';

const ImageLinkComponent = ({ classes, src, href, circular, width, height, className }) => (
  <div className={classnames(classes.logo, className)}>
    <Image src={src} href={href} circular={circular} width={width} height={height} target="_blank" />
  </div>
);

export default injectSheet(styles)(ImageLinkComponent);
