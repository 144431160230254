/** @format */

import { actionSucceeds } from '../lib/helpers';
import { Map } from 'immutable';
import request from './api';

export const types = {
  fetchReport: 'REPORT_FETCH',
  fetchFinancials: 'FINANCIALS_FETCH',
  fetchContacts: 'CONTACTS_FETCH',
};

export function fetchFinancials(companyId) {
  return request(types.fetchFinancials, '/xero/financials', 'GET', null, [{ companyId }]);
}

export function fetchContacts(companyId) {
  return request(types.fetchContacts, '/xero/contacts', 'GET', null, [{ companyId }]);
}

export function fetchReport(reportId, companyId, params) {
  const queryParams = params || [];
  return request(types.fetchReport, '/xero/reports', 'GET', null, [
    { companyId },
    { report: reportId },
    ...queryParams,
  ]);
}

const INITIAL_STATE = Map({
  report: {},
  financials: {},
  contacts: [],
});

export default function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case types.fetchReport:
      return state.merge(
        Map({
          isLoading: action.isLoading,
          report: actionSucceeds(action) ? action.body.report : {},
        })
      );
    case types.fetchFinancials:
      return state.merge(
        Map({
          isLoading: action.isLoading,
          financials: actionSucceeds(action) ? action.body : {},
        })
      );
    case types.fetchContacts:
      return state.merge(
        Map({
          isLoading: action.isLoading,
          contacts: actionSucceeds(action) ? action.body : [],
        })
      );
    default:
      return state;
  }
}
