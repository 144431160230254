/** @format */

import React, { Component } from 'react';
import { Route } from 'react-router-dom';
import injectSheet from 'react-jss';
import { Grid, Menu, Segment, Icon, Header, Dropdown } from 'semantic-ui-react';
import { connect } from 'react-redux';
import 'react-toastify/dist/ReactToastify.css';

import { loadUser, editUser } from '../../modules/user';
import { fetchCompany, getBoxToken, getBoxFolders, getBoxFiles } from '../../modules/company';
import { listTaskByCompany } from '../../modules/task';
import { listContactByCompany } from '../../modules/contact';
import { fetchFinancials } from '../../modules/reports';

import auth from '../../lib/auth';
import styles from './app.styles';
import Logo from '../../components/common/logo/logo';
import 'box-ui-elements/dist/explorer.css';
import Admin from '../admin/admin';
import Loading from '../../components/common/loading/loading';
import Overview from '../dashboard/views/overview/overview';
import Financials from '../dashboard/views/financials/financials';
import Messages from '../dashboard/views/messages/messages';
import DataRoom from '../dashboard/views/data-room/data-room';
import ImageLink from '../../components/common/image-link/image-link';
import ClerkyLogo from '../../images/clerky.png';
import XeroLogo from '../../images/xero.svg';
import GustoLogo from '../../images/gusto2.png';
import ExpensifyLogo from '../../images/expensify.svg';
import AirbaseLogo from '../../images/airbase.png';
import CartaLogo from '../../images/carta.png';
import HumanInterestLogo from '../../images/human-interest.png';
import BrexLogo from '../../images/brex.png';
import { ToastContainer } from 'react-toastify';
import * as queryString from 'query-string';

const ROUTE_MAP = {
  dashboard: {
    name: 'Dashboard',
    loaders: [
      fetchCompany,
      listContactByCompany,
      listTaskByCompany,
      fetchFinancials,
      getBoxToken,
      getBoxFolders,
      getBoxFiles,
    ],
  },
  callback: {
    name: 'Dashboard',
    loaders: [
      fetchCompany,
      listContactByCompany,
      listTaskByCompany,
      fetchFinancials,
      getBoxToken,
      getBoxFolders,
      getBoxFiles,
    ],
  },
  messages: {
    name: 'Messages',
    loaders: [fetchCompany],
  },
  dataRoom: {
    name: 'Data Room',
    loaders: [fetchCompany, listContactByCompany, getBoxToken],
  },
  financials: {
    name: 'Financials',
    loaders: [fetchCompany, listContactByCompany],
  },
  admin: {
    name: 'Admin',
    loaders: [fetchCompany, listContactByCompany],
  },
};

class App extends Component {
  constructor(props) {
    super(props);
    const pathname = props.location.pathname.split('/');
    this.state = { activeItem: pathname.length > 0 ? pathname[1] : 'dashboard' };
  }

  async componentDidMount() {
    const { dispatch } = this.props;
    const { activeItem } = this.state;
    const email = localStorage.getItem('email');
    await dispatch(loadUser(email));
    const { user } = this.props;
    if (user.get('user')) {
      await this.loadData(user.get('user').activeCompany, ROUTE_MAP[activeItem].loaders);
    }
  }

  handleItemClick = async (e, { name }) => {
    const { user, history } = this.props;
    this.setState({ activeItem: name, loading: true });
    if (user.get('user')) {
      await this.loadData(user.get('user').activeCompany, ROUTE_MAP[name].loaders);
    }
    this.setState({ loading: false });
    history.push(`/${name}`);
  };

  async loadData(companyId, loaders) {
    const { dispatch } = this.props;
    const mappedLoaders = loaders.map((loader) => dispatch(loader(companyId)));
    await Promise.all(mappedLoaders).then(() => this.printDebugInfo());
  }

  printDebugInfo() {
    if (process.env.NODE_ENV !== 'production') {
      console.log(this.props.user.get('user'));
      console.log(this.props.company.get('company'));
    }
  }

  render() {
    const { classes, user } = this.props;
    const { loading } = this.state;
    const activeUser = user.get('user') || {};
    return (
      <Grid className={classes.baseApp}>
        <Grid.Row className={classes.header}>
          <Grid.Column width={8} textAlign="left">
            <Logo title={'StartOp'} />
          </Grid.Column>
          <Grid.Column width={8} textAlign="right" className={classes.nameColumn}>
            {this.renderUserMenu(activeUser)}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row className={classes.body}>
          <Grid.Column width={3} className={classes.sidebar}>
            {this.renderMenuOptions()}
            {this.renderPartnerLinks()}
          </Grid.Column>
          {!loading ? this.renderRoutes() : <Loading />}
        </Grid.Row>
        <ToastContainer />
      </Grid>
    );
  }

  renderRoutes() {
    const { user } = this.props;
    const { activeItem } = this.state;
    const activeUser = user.get('user') || {};
    const userExists = activeUser.email;
    return userExists && ROUTE_MAP[activeItem] ? this.baseAppWithRoutes() : this.invalidUser();
  }

  baseAppWithRoutes() {
    const { classes } = this.props;
    const { activeItem } = this.state;
    const activeUser = this.props.user.get('user');
    return (
      <Grid.Column textAlign="left" stretched width={13} className={classes.container}>
        {
          <Grid.Row style={{ maxHeight: 45 }}>
            <Segment id={'dashboard'} className={classes.menu}>
              <Header as="h3" className={classes.pageName}>
                {activeItem !== 'dashboard'
                  ? ROUTE_MAP[activeItem].name
                  : `${activeUser ? this.getActiveCompany().name + "'s" : ''} Dashboard`}
              </Header>
            </Segment>
          </Grid.Row>
        }
        <Grid.Row style={{ height: '100%' }}>
          <Route path="/dashboard" component={Overview} />
          <Route exact path="/dataRoom/" component={DataRoom} />
          <Route
            exact
            path="/dataRoom/folder/:currentFolderId"
            render={({ match }) => {
              return <DataRoom {...match.params} search={queryString.parse(this.props.location.search)} />;
            }}
          />
          <Route
            exact
            path="/dataRoom/file/:currentFileId"
            render={({ match }) => <DataRoom {...match.params} />} // doesn't currently work
          />
          <Route path="/financials" component={Financials} />
          <Route path="/messages" component={Messages} />
          <Route path="/admin" component={Admin} />
        </Grid.Row>
      </Grid.Column>
    );
  }

  renderUserMenu() {
    const activeUser = this.props.user.get('user');
    const { classes } = this.props;
    return (
      activeUser && (
        <Dropdown
          style={{ boxShadow: '0 0 0 0 !important' }}
          className={classes.nameDropdown}
          item
          direction="left"
          text={`${activeUser.firstName} ${activeUser.lastName} | ${
            activeUser ? this.getActiveCompany().name : 'No Selected Company'
          }`}
          // icon={<Icon name="user circle outline" />} // TODO: User avatar?
        >
          <Dropdown.Menu>
            <Dropdown.Header icon="building" content="Companies" />
            {activeUser.companies &&
              activeUser.companies.map((company) => (
                <Dropdown.Item
                  key={company._id}
                  text={company.name}
                  active={company._id === activeUser.activeCompany}
                  onClick={() => this.setActiveCompany(activeUser._id, company)}
                />
              ))}
            <Dropdown.Divider />
            <Dropdown.Item text={'Log Out'} onClick={this.logout} />
          </Dropdown.Menu>
        </Dropdown>
      )
    );
  }

  renderMenuOptions() {
    const { activeItem } = this.state;
    const { classes, user } = this.props;
    const activeUser = user.get('user') || {};
    const isAdmin = activeUser.isAdmin;

    return (
      <Menu fluid vertical tabular style={{ marginTop: 10 }}>
        <Menu.Header as="h5" className={classes.navigationHeader} />
        <Menu.Item
          name="dashboard"
          active={activeItem === 'dashboard' || activeItem === 'callback'}
          onClick={this.handleItemClick}
        >
          <Icon name="compass outline" className={classes.menuIcon} />
          Dashboard
        </Menu.Item>
        <Menu.Item name="dataRoom" active={activeItem === 'dataRoom'} onClick={this.handleItemClick}>
          <Icon name="hdd outline" className={classes.menuIcon} />
          Data Room
        </Menu.Item>
        <Menu.Item name="financials" active={activeItem === 'financials'} onClick={this.handleItemClick}>
          <Icon name="chart bar outline" className={classes.menuIcon} />
          Financials
        </Menu.Item>
        <Menu.Item name="messages" active={activeItem === 'messages'} onClick={this.handleItemClick}>
          <Icon name="paper plane outline" className={classes.menuIcon} />
          Messages
        </Menu.Item>
        {isAdmin && (
          <Menu.Item name="admin" active={activeItem === 'admin'} onClick={this.handleItemClick}>
            <Icon name="user circle outline" className={classes.menuIcon} />
            Admin
          </Menu.Item>
        )}
      </Menu>
    );
  }

  renderPartnerLinks() {
    const { classes } = this.props;

    return (
      <Menu className={classes.bottomMenu} fluid vertical tabular>
        <Header as="h5" className={classes.partnerHeader}>
          {'Integration & Partners'}
        </Header>
        <ImageLink
          className={classes.xeroLogo}
          src={XeroLogo}
          href={'https://login.xero.com/identity/user/login'}
          width={40}
          circular={true}
        />
        <ImageLink src={BrexLogo} href={'https://dashboard.brex.com/'} width={70} />
        <ImageLink src={GustoLogo} href={'https://app.gusto.com/login'} width={100} />
        <ImageLink src={ClerkyLogo} href={'https://app.clerky.com/'} width={70} />
        <ImageLink src={HumanInterestLogo} href={'https://app.humaninterest.com/login'} width={100} />
        <ImageLink
          className={classes.expensifyLogo}
          src={ExpensifyLogo}
          href={'https://www.expensify.com/'}
          width={90}
        />
        <ImageLink
          className={classes.cartaLogo}
          src={CartaLogo}
          href={'https://login.app.carta.com/credentials/login/'}
          width={75}
        />
        <ImageLink className={classes.airBaseLogo} src={AirbaseLogo} href={'https://www.myairbase.com/'} width={80} />
      </Menu>
    );
  }

  invalidUser() {
    const { classes, user } = this.props;
    if (user.get('isLoading')) return <div />;

    return (
      <Grid.Column textAlign="left" stretched width={13} className={classes.container}>
        <Grid.Row style={{ maxHeight: 45 }}>
          <Segment id={'dashboard'} className={classes.menu}>
            <Header as="h3" className={classes.pageName}>
              {'Something went wrong. Please contact the administrator.'}
            </Header>
          </Segment>
        </Grid.Row>
      </Grid.Column>
    );
  }

  getActiveCompany() {
    const { user } = this.props;
    const activeUser = user.get('user') || {};
    let company = {};
    if (activeUser) {
      activeUser.companies.map((c) => {
        if (activeUser.activeCompany === c._id) {
          company = c;
        }
      });
    }
    return company;
  }

  async setActiveCompany(userId, company) {
    const { user, dispatch } = this.props;
    const activeUser = user.get('user') || {};
    const newUser = { ...activeUser, activeCompany: company._id, company: company };
    await dispatch(editUser(userId, newUser));
    await dispatch(loadUser(activeUser.email));
    await this.loadData(company._id, ROUTE_MAP[this.state.activeItem].loaders);
  }

  logout = () => {
    auth.signOut();
  };
}

const withStyles = injectSheet(styles);
const withState = connect((store) => store);

export default withStyles(withState(App));
