/** @format */

import React from 'react';
import injectSheet from 'react-jss';
import { List } from 'semantic-ui-react';
import styles from './contact.styles';
import { parsePhone } from '../../../support/parsers';

const ContactComponent = ({ classes, name, title, phone, email }) => (
  <List.Item className={classes.contact}>
    <List.Content>
      <List.Header as="p" className={classes.name}>
        {name}
      </List.Header>
      <List.Description className={classes.description}>
        <p className={classes.contactDetails}>
          <span className={classes.title}>{title}</span>
          <br />
          <br />
          <a href={`mailto:${email}`}>{email}</a>
          <br />
          {parsePhone(phone)}
        </p>
        <p />
      </List.Description>
    </List.Content>
  </List.Item>
);

export default injectSheet(styles)(ContactComponent);
