/** @format */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Grid, Menu } from 'semantic-ui-react';
import injectSheet from 'react-jss';

import styles from './financials.styles';
import Report from '../../../../components/common/report/report';

import { fetchCompany } from '../../../../modules/company';
import { listContactByCompany } from '../../../../modules/contact';
import { fetchFinancials, fetchContacts } from '../../../../modules/reports';
import { fetchReport } from '../../../../modules/reports';

class Financials extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeItem: 'Balance Sheet',
      activeReport: '1',
    };
  }

  handleItemClick(name, reportId) {
    this.setState({ activeItem: name, activeReport: reportId });
    this.fetchReport(reportId);
  }

  async componentDidMount() {
    const { user } = this.props;
    if (user.get('user')) {
      await this.loadCompanyData(user.get('user').activeCompany);
    }
    this.fetchReport('1');
  }

  async loadCompanyData(companyId) {
    const { dispatch } = this.props;
    await Promise.all([
      dispatch(fetchCompany(companyId)),
      dispatch(listContactByCompany(companyId)),
      dispatch(fetchFinancials(companyId)),
      dispatch(fetchContacts(companyId)),
    ]);
  }

  fetchReport(reportId, params) {
    const { dispatch, company } = this.props;
    dispatch(fetchReport(reportId, company.get('company')._id, params));
  }

  render() {
    const { classes } = this.props;
    const { activeItem, activeReport } = this.state;
    return (
      <Grid>
        <Grid.Row className={classes.menu}>
          <Menu pointing secondary>
            <Menu.Item
              name="Balance Sheet"
              active={activeItem === 'Balance Sheet'}
              onClick={() => this.handleItemClick('Balance Sheet', '1')}
            />
            <Menu.Item
              name="Executive Summary"
              active={activeItem === 'Executive Summary'}
              onClick={() => this.handleItemClick('Executive Summary', '6')}
            />
            <Menu.Item
              name="Profit and Loss"
              active={activeItem === 'Profit and Loss'}
              onClick={() => this.handleItemClick('Profit and Loss', '3')}
            />
            <Menu.Item
              name="Bank Summary"
              active={activeItem === 'Bank Summary'}
              onClick={() => this.handleItemClick('Bank Statement', '4')}
            />
            <Menu.Item
              name="Accounts Payable"
              active={activeItem === 'Accounts Payable'}
              onClick={() => this.handleItemClick('Accounts Payable', '9')}
            />
          </Menu>
        </Grid.Row>
        <Grid.Row className={classes.report}>{<Report activeReport={activeReport} />}</Grid.Row>
      </Grid>
    );
  }
}

const withStyles = injectSheet(styles);
const withState = connect(store => store);

export default withStyles(withState(Financials));
