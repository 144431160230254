/** @format */

import React from 'react';
import injectSheet from 'react-jss';
import { Checkbox, List } from 'semantic-ui-react';
import styles from './task.styles';

const TaskComponent = ({ classes, name, status, completeTask }) => (
  <List.Item className={classes.contact}>
    <List.Content>
      <List.Header as="p" className={classes.name}>
        {name}
      </List.Header>
      <List.Description className={classes.description}>
        {status == 'submitted' ? <Checkbox onClick={completeTask}></Checkbox> : { status }}
      </List.Description>
    </List.Content>
  </List.Item>
);

export default injectSheet(styles)(TaskComponent);
