/** @format */
import { Background } from '../../../styles/variables';

export default {
  logo: {
    marginLeft: '-20px !important',
    marginTop: '-45px !important',
    color: Background,
    height: '120px',
    width: '180px',
    float: 'left',
    fontFamily: 'Proxima Nova, Open Sans, sans-serif',
  },
};
