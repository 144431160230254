/** @format */

import React from 'react';
import injectSheet from 'react-jss';
import styles from './logo.styles';
import logo from '../../../images/transparent.png';

const LogoComponent = ({ classes }) => <img className={classes.logo} src={logo} alt="logo" />;

export default injectSheet(styles)(LogoComponent);
