/** @format */

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import injectSheet from 'react-jss';
import styles from './overview.styles';
import { Grid, List } from 'semantic-ui-react';

import Loading from '../../../../components/common/loading/loading';
import Card from '../../../../components/common/card/card';
import Number from '../../../../components/common/number/number';
import Contact from '../../../../components/common/contact/contact';
import Information from '../../../../components/common/information/information';
import { CashBalance, MonthlyExpenses, MonthlyRevenue } from '../../../../styles/variables';
import { parseDate } from '../../../../support/parsers';
import Task from '../../../../components/common/task/task';
import { createTask, editTask } from '../../../../modules/task';
import FileUpload from '../../../../components/common/file-upload/file-upload';

class Overview extends Component {
  render() {
    const { classes, company, contact, task, reports, dispatch } = this.props;
    const isCompanyLoading = company.get('isLoading');
    const areContactsLoading = contact.get('isLoading');
    const areTasksLoading = task.get('isLoading');

    const financials = reports.get('financials');
    let d = new Date();
    d.setMonth(d.getMonth() - 1);
    d.setDate(1);
    const dateDisplay = parseDate(d, 'MMM-YY'); // PLACEHOLDER
    // TODO: get dateDisplay from financials instead of picking today
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
    });
    return (
      <Grid className={classes.dash}>
        <Grid.Row />
        <Grid.Row className={classes.numbers}>
          <Grid.Column className={classes.number} width={5}>
            <Number
              name={'Cash Balance'}
              date={'Current'}
              icon={'check circle outline'}
              color={CashBalance}
              number={financials.cashBalance ? formatter.format(parseFloat(financials.cashBalance)) : '...'}
            />
          </Grid.Column>
          <Grid.Column className={classes.number} width={5}>
            <Number
              name={'Monthly Revenue'}
              date={dateDisplay}
              icon={'arrow alternate circle up outline'}
              color={MonthlyRevenue}
              number={financials.monthlyRevenue ? formatter.format(parseFloat(financials.monthlyRevenue)) : '...'}
            />
          </Grid.Column>
          <Grid.Column className={classes.number} width={5}>
            <Number
              name={'Monthly Expenses'}
              date={dateDisplay}
              icon={'arrow alternate circle down outline'}
              color={MonthlyExpenses}
              number={financials.monthlyExpenses ? formatter.format(parseFloat(financials.monthlyExpenses)) : '...'}
            />
          </Grid.Column>
        </Grid.Row>
        <Grid.Row className={classes.cards}>
          <Grid.Column className={classes.column} width={3}>
            {areContactsLoading ? <Loading /> : this.renderContacts()}
          </Grid.Column>
          <Grid.Column className={classes.column} width={3}>
            {areTasksLoading ? <Loading /> : this.renderTasks()}
          </Grid.Column>
          <Grid.Column className={classes.column} width={3}>
            {isCompanyLoading ? <Loading /> : this.renderCaptableInformation()}
          </Grid.Column>
          <Grid.Column className={classes.column} width={3}>
            {isCompanyLoading ? <Loading /> : this.renderOperationInformation()}
          </Grid.Column>
          <Grid.Column className={classes.column} width={4}>
            {this.renderFileList()}
          </Grid.Column>
        </Grid.Row>
        <Grid.Row>
          <Grid.Column className={classes.column} width={16}>
            <FileUpload
              setFile={(file) => {
                const { user } = this.props;
                const companyId = user.get('user').activeCompany;
                console.log(file);
                let t = {};
                t.name = file.name;
                t.status = 'submitted';
                t.companyId = companyId;
                t.attachmentName = file.name;
                var reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = function () {
                  t.attachment = reader.result;
                  dispatch(createTask(t)).then((res) => {
                    console.log(res);
                  });
                };
                reader.onerror = function (error) {
                  console.log('Error: ', error);
                };
              }}
            />
          </Grid.Column>
        </Grid.Row>
      </Grid>
    );
  }

  renderContacts() {
    const companyContacts = this.props.contact.get('contacts');
    return (
      <Card title={'Contacts'}>
        <List>
          {companyContacts &&
            companyContacts.map((contact) => (
              <Contact
                key={contact._id}
                name={contact.name}
                title={contact.title}
                phone={contact.phone}
                email={contact.email}
              />
            ))}
        </List>
      </Card>
    );
  }
  renderTasks() {
    const companyTasks = this.props.task.get('tasks');
    console.log(this.props);
    const dispatch = this.props.dispatch;
    return (
      <Card title={'Tasks'}>
        <List>
          {companyTasks &&
            companyTasks.map((task) => (
              <Task
                key={task._id}
                name={task.name}
                status={task.status}
                completeTask={() => {
                  let t = task;
                  t.status = 'Completed';
                  dispatch(editTask(task._id, t)).then((res) => {
                    console.log(res);
                  });
                }}
              />
            ))}
        </List>
      </Card>
    );
  }

  renderCaptableInformation() {
    const userCompany = this.props.company.get('company');
    const captableLabelMap = {
      'As Of Date': userCompany.asOfDate,
      FDSO: userCompany.fullyDilutedSharesOutstanding,
      'Current Preferred Price': userCompany.currentPreferredPrice,
      'Current Common Price': userCompany.currentCommonPrice,
      // 'Current Valuation': userCompany.currentValuation,
      '409a Date': userCompany.dateOf409aValuation,
    };

    return (
      <Card title={'Cap Table'}>
        <List>
          {Object.keys(captableLabelMap).map((key) => (
            <Information key={key} label={key} value={captableLabelMap[key]} />
          ))}
        </List>
      </Card>
    );
  }

  renderOperationInformation() {
    const userCompany = this.props.company.get('company');
    const companyLabelMap = {
      EIN: userCompany.ein,
      'Formation Date': userCompany.dateOfIncorporation,
      'State Corp #': userCompany.stateCorporationNumber,
      'NAICS #': userCompany.naics,
      Address: userCompany.address,
      'Checking Account': userCompany.checking,
      'Routing Number': userCompany.routing,
    };

    return (
      <Card title={'Operation Information'}>
        <List>
          {Object.keys(companyLabelMap).map((key) => (
            <Information key={key} label={key} value={companyLabelMap[key]} />
          ))}
        </List>
      </Card>
    );
  }

  renderFileList() {
    const { company } = this.props;
    const boxFolders = company.get('boxFolders');
    const boxFiles = company.get('boxFiles');

    return (
      <Card title={'Critical Files'}>
        <List link>
          {boxFolders &&
            Array.isArray(boxFolders) &&
            boxFolders.map((folder) => (
              <List.Item key={folder.id}>
                <List.Icon name="folder outline" />
                <List.Content>
                  <Link to={`/dataRoom/folder/${folder.id}`}>
                    {folder.name.replace('critical', '').replace('Critical', '').replace('(', '').replace(')', '')}
                  </Link>
                </List.Content>
              </List.Item>
            ))}
          {boxFiles &&
            Array.isArray(boxFiles) &&
            boxFiles.map((file) => (
              <List.Item key={file.id}>
                <List.Icon name="file outline" />
                <List.Content>
                  <Link to={`/dataRoom/folder/${file.id}?file=true`}>
                    {file.name.replace('critical', '').replace('Critical', '').replace('(', '').replace(')', '')}
                  </Link>
                </List.Content>
              </List.Item>
            ))}
        </List>
      </Card>
    );
  }
}

const withState = connect((store) => {
  return store;
});

export default injectSheet(styles)(withState(Overview));
